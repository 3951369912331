'use client';

import React from 'react';

import { LazyMotion } from 'framer-motion';
import PropTypes from 'prop-types';

const motionFeatures = () =>
    import('utils/motion-features').then(res => res.default);

const FramerMotionContextProvider = ({ children }) => {
    return (
        <LazyMotion strict features={motionFeatures}>
            {children}
        </LazyMotion>
    );
};

export default FramerMotionContextProvider;

FramerMotionContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
